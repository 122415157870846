import React, { Component } from "react"
import { ReCaptcha } from "react-recaptcha-v3"
import axios from 'axios'
import IntPhoneInput from './IntlPhoneNumber'
import UnknownUserContactForm from './UnknownUserContactForm'
import KnownUserContactForm from './KnownUserContactForm'
// import withCaptcha from "../../utils/withCaptcha";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";

import { FormattedMessage } from 'react-intl'
import AuthService from '../../../services/auth.service'
import { BiArrowBack } from "react-icons/bi"


class SellerContactForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: 1,
      userId: null,
      seller: null,
      messageSent: false
    };
  }
  getUserData(userId) {
    axios.get(
      `${process.env.GATSBY_API_URL}/user/${userId}`
    ).then((response) => {
      let user = response.data
      if (user) {
        this.setState({
          'userId': user._id
        })
      }
    })
  }

  getSellerData(userId) {
    console.log('getSellerData', userId)
    axios.get(
      `${process.env.GATSBY_API_URL}/user/${userId}`
    ).then((response) => {
      this.setState({ seller: response.data })
    })
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser()
    this.getSellerData(this.props.sellerId)
    // this.getSellerData('60291b89d1e718ae79aeae48') // Force to Olivier Livet
    if (user && user.userId) {
      this.getUserData(user.userId)

    }
  }

  render() {
    const { userId, seller, messageSent } = this.state
    return (
      <Box
        p={{ base: 5, xl: 20 }}
        backgroundColor="gray.50"
        border={{ lg: "solid 1px #EEE" }}
        borderRadius={{ lg: "10px" }}
        minH={{ base: '100vh', lg: 'auto' }}
      >
        <Button
          onClick={this.props.close}
          mb={10}
          leftIcon={<BiArrowBack />}
        >
          <FormattedMessage id="seller.contact.form.back.to.ad" />
        </Button>
        {!userId && seller ?
          <UnknownUserContactForm
            close={this.props.close}
            saveUserId={(userId) => {
              this.setState({ userId: userId },
                this.getUserData(userId)
              )
            }}
          />

          : userId && seller && !messageSent ?
            <KnownUserContactForm
              userId={userId}
              seller={seller}
              adBackendId={this.props.adBackendId}
              saveUserId={(userId) => {
                this.setState({ userId: userId },
                  this.getUserData(userId)
                )
              }}
              handleMessageSent={(value) => { this.setState({ messageSent: value }) }}
            />

            : userId && seller && messageSent ?
              <Alert mb={4} status="success" borderRadius={3}>
                <AlertIcon />
                <FormattedMessage id="ad.message.send.sucess.notice" />
              </Alert>
              : <Box><Spinner size='sm' color='blue.500' /> Chargement ou erreur</Box>
        }
      </Box>
    )
  }
}

// export default withCaptcha(SellerContactForm);
export default SellerContactForm;