import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Divider,
    Flex,
    Input,
    FormControl,
    FormLabel,
    Stack,
    Text,
    Textarea,
    SimpleGrid,
    Tag,
    TagLabel,
    TagCloseButton,
    FormHelperText
} from "@chakra-ui/react";

import { FormattedMessage } from 'react-intl'

const KnownUserContactForm = ({
    adBackendId,
    userId,
    seller,
    handleMessageSent
}) => {

    const [user, setUser] = useState(null)
    const [message, setMessage] = useState(null)
    useEffect(() => {
        getUserData(userId)
    }, [])

    const getUserData = (userId) => {
        Axios.get(
            `${process.env.GATSBY_API_URL}/user/${userId}`
        ).then((response) => {
            console.log(response.data)
            setUser(response.data)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        Axios.post(
            `${process.env.GATSBY_API_URL}/discussion/`,
            {
                ad: adBackendId,
                sender: userId,
                receiver: seller._id,
                content: message
            }

        ).then((response) => {
            console.log(response)
            handleMessageSent(true)
        })
    }
    return (
        <Box as='form' onSubmit={(e) => handleSubmit(e)}>
            <FormControl>
                <FormLabel htmlFor="contact_phone">Message&nbsp;:{" "}</FormLabel>
                <Textarea
                    id="contact_message"
                    name="contact_message"
                    placeholder={process.env.GATSBY_LANG === 'en' ? 'Hi,...' : "Bonjour,..."}
                    //   value={this.state.contact_message}
                    onChange={(e) => setMessage(e.target.value)}
                    //   onBlur={this.handleChange}
                    minH={{ xd: '250px', lg: '350px' }}
                />
                <FormHelperText display="flex" alignItems="center">
                    {seller.locale === 'fr' ?
                        <>
                            <FormattedMessage id="seller.contact.form.language.fr.notice" />
                            <Box ml='.2rem' as="span" aria-label="flag">
                                <FormattedMessage id="main.flag.france" />
                            </Box>
                        </>
                        :
                        <>
                            <FormattedMessage id="seller.contact.form.language.en.notice" />
                            <Box ml='.2rem' as="span" aria-label="flag">
                                <FormattedMessage id="main.flag.england" />
                            </Box>
                        </>
                    }
                </FormHelperText>
            </FormControl>

            <Button disabled={!message} mt={4} colorScheme="blue" type="submit">
                {message ?
                    <FormattedMessage id="seller.contact.form.send.message" />
                    : <FormattedMessage id="seller.contact.form.fill.first" />
                }
            </Button>
        </Box>
    )
}

export default KnownUserContactForm