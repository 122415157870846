import React, { useState } from 'react'

import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Divider,
    Flex,
    Input,
    FormControl,
    FormLabel,
    Stack,
    Text,
    Textarea,
    SimpleGrid,
    Tag,
    TagLabel,
    TagCloseButton,
    FormHelperText
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl'
import isValidEmail from '../../utils/emailValidator'
import Axios from 'axios'
import IntPhoneInput from './IntlPhoneNumber'


const UnknownUserContactForm = (props) => {
    const [validEmail, setValidEmail] = useState(false)
    const [step, setStep] = useState(0)

    const [contactName, setContactName] = useState()
    const [email, setEmail] = useState('')
    const [emailConfirmation, setEmailConfirmation] = useState('')
    const [phone, setPhone] = useState('')

    const handleEmailChange = (value) => {
        console.log(value)
        setEmail(value)
        if (isValidEmail(value)) {

            setValidEmail(true)

            Axios.get(
                `${process.env.GATSBY_API_URL}/user/email/${value}`
            ).then((response) => {
                if (response && response.data) {
                    props.saveUserId(response.data);
                }
            })
        }
    }

    const handleBlurEmail = (value) => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        Axios.post(
            `${process.env.GATSBY_API_URL}/user/`,
            {
                name: contactName,
                email: email,
                phone: phone,
                acceptNewsletter: true,
                locale: process.env.GATSBY_LANG,
                origine: 'ad-contact',

            }
        ).then((response) => props.saveUserId(response.data._id))
    }

    return (

        step === 0 ?
            <Stack
                // display={userData ? 'none' : 'initial'}
                spacing={4}
            >
                <Text
                    as="legend">
                    <FormattedMessage id="seller.contact.form.privacy.disclaimer" />
                </Text>
                <FormControl>
                    <FormLabel htmlFor="contact_email">Email :{" "}</FormLabel>
                    <Input
                        id="contact_email"
                        name="contact_email"
                        type="email"
                        placeholder="christophe@santa-maria.fr"
                        //   value={contact_email}
                        //   onChange={this.handleChange}
                        //   onBlur={this.handleChange}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        onBlur={(e) => handleBlurEmail(e.target.value)}
                        size="md"
                        required
                    />
                </FormControl>
                <Box>
                    <Button
                        colorScheme='blue'
                        isDisabled={!validEmail}
                        onClick={(e) => setStep(2)}
                    >
                        <FormattedMessage id="main.validate" />
                    </Button>
                </Box>

            </Stack>

            : validEmail && step === 2 ?

                <Stack
                    // display={userData ? 'none' : 'initial'}
                    spacing={4}
                    as='form'
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <Box>
                        <FormattedMessage id="seller.contact.form.first.message.disclaimer" />
                    </Box>
                    <FormControl>
                        <FormLabel htmlFor="contactName"><FormattedMessage id="seller.contact.form.name" />&nbsp;:{" "}</FormLabel>
                        <Input
                            id="contactName"
                            name="contactName"
                            placeholder="Christophe Colomb"
                            value={contactName ? contactName : ''}
                            onChange={(e) => setContactName(e.target.value)}
                            // onChange={this.handleChange}
                            // value={contact_name}
                            size="md"
                        />
                    </FormControl>
                    <SimpleGrid columns="2" gap=".5rem">
                        <FormControl>
                            <FormLabel htmlFor="contact_email">Email&nbsp;:{" "}</FormLabel>
                            <Input
                                id="contact_email"
                                name="contact_email"
                                type="email"
                                placeholder="christophe@santa-maria.fr"
                                value={email ? email : ''}
                                onChange={(e) => setEmail(e.target.value)}
                                // onBlur={this.handleChange}
                                size="md"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="contact_email">Confirmation&nbsp;:{" "}</FormLabel>
                            <Input
                                id="contact_email_confirmation"
                                name="contact_email_confirmation"
                                type="email"
                                placeholder="christophe@santa-maria.fr"
                                value={emailConfirmation ? emailConfirmation : ''}
                                onChange={(e) => setEmailConfirmation(e.target.value)}
                                // value={contact_email_confirmation}
                                // onChange={this.handleChange}
                                // onBlur={this.handleChange}
                                borderColor={
                                    (email !== '' && emailConfirmation !== '')
                                        && (email !== emailConfirmation) ? 'red.300' : 'gray.300'
                                }
                                size="md"
                                required
                            />
                        </FormControl>
                    </SimpleGrid>
                    <FormControl>
                        <FormLabel htmlFor="contact_phone"><FormattedMessage id="seller.contact.form.phone" /> :{" "}</FormLabel>
                        <IntPhoneInput
                            // value={contact_phone}
                            updatePhoneNumber={(value) => setPhone(value)}
                        />
                    </FormControl>

                    <Box>
                        <Button
                            colorScheme='blue'
                            type='submit'
                            isDisabled={
                                !email
                                    || !contactName
                                    || !email
                                    || (email !== emailConfirmation)
                                    || !phone
                                    ? true : false}
                        >
                            Valider
                        </Button>
                    </Box>

                    <Divider />

                    <Alert status="info">
                        <AlertIcon />
                        <FormattedMessage id="seller.contact.form.filling.disclaimer" />
                    </Alert>

                </Stack>
                : null
    )
}

export default UnknownUserContactForm