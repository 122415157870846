import React, { useContext } from "react"
import AdCardPortraitLarge from "./PortraitLarge"
import SellerContactForm from "./SellerContactForm"
import { injectIntl } from "react-intl"
import { Box, Center, Spinner } from "@chakra-ui/react";
import Debug from "../../utils/Debug";
import { UserContext } from "../userContext";

const contentful = require("contentful")
const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
})

const AdLoader = ({ adId, ctaLabel, handleUnload }) => {

    const user = useContext(UserContext).user


    const
        [isLoading, setIsLoading] = React.useState(true),
        [ad, setAd] = React.useState(),
        [contactIsOpen, setContactIsOpen] = React.useState();

    React.useEffect(
        () => {
            getAdData();
        },
        [adId]
    )

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isLoading: true,
    //         ad: null,
    //         contactIsOpen: false
    //     }
    // }
    const getAdData = () => {
        client.getEntry(adId, { locale: process.env.GATSBY_LANG }).then(function (entry) {
            setAd(entry);
            setIsLoading(false);
        })
    }
    const contactHandleClose = () => {
        setContactIsOpen(false);
    }
    const contactHandleOpen = () => {
        setContactIsOpen(true);
    }

    return (
        ad ? (

            <Box
                maxW={'750px'}
                mx='auto'
                borderRadius={{ base: 0, md: "10px" }}
                overflow={'hidden'}
                id='ad-modal'
                my={{ base: 0, lg: 10 }}
                minH={{ base: '100vh', lg: 'auto' }}
                bgColor='white'
                boxShadow={'xl'}
            >
                {contactIsOpen ?
                    <SellerContactForm
                        close={() => { contactHandleClose() }}
                        adBackendId={ad.fields.backendId}
                        sellerId={ad.fields.sellerId}
                    />
                    :
                    <AdCardPortraitLarge
                        key={ad.sys.id}
                        contentful_id={ad.sys.id}
                        ad={ad.fields}
                        id={ad.sys.id}
                        // ctaLabel={ctaLabel ? ctaLabel : this.props.intl.formatMessage({ id: 'main.cta.ad.contact' })}
                        ctaLabel={ctaLabel ? ctaLabel : "close"}
                        // closeButtonLabel={this.props.intl.formatMessage({ id: 'main.close' })}
                        closeButtonLabel="close"
                        context="main-website-modal"
                        backAction={handleUnload}
                        isViewingPictures={false}
                        contactOpen={() => { contactHandleOpen() }}
                        user={user}
                    />}
            </Box>

        ) : <Center minH='100vh'><Spinner colorScheme={'white'} /></Center>
    )
}

export default injectIntl(AdLoader)